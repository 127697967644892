<template>
    <div>
        <!-- Loading Animation -->
        <div v-if="loading" class="loading-container">
            <div class="spinner"></div>
            <p>Loading case details...</p>
        </div>

        <!-- Error Message when No Cases are Found -->
        <div v-if="!loading && error === 'No cases found for the logged-in user.'">
            <div class="alert alert-dismissible alert-danger">
                <strong><i class="fa-solid fa-circle-info"></i> You currently have no submitted cases.</strong><br>
                Start a new case whenever you're ready.
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Let us help you</h5>
                    <p class="card-text">Don't let a false ban keep you down. Start your case and we'll do our best to
                        help you.</p>
                    <router-link to="/account/case/submit" class="btn btn-success"><i
                            class="fa-solid fa-arrow-right-to-bracket"></i>
                        Submit a new case</router-link>
                </div>
            </div>

        </div>

        <!-- Other Content (e.g., Displaying Cases) -->
        <div v-if="!loading && sessionData.hasSubmittedCase == true">
            <div class="card shadow-sm p-3 mb-5 bg-body-tertiary rounded">
    <div class="card-body">
        <!-- Header Section -->
        <h4 class="card-title text-center">Ban Challenge Support Portal</h4>
        <h6 class="card-subtitle mb-4 text-muted text-center">Manage your Call of Duty ban challenge on legal grounds</h6>

        <!-- Legal Case Status & Progress Tracker -->
        <div class="mb-4">
            <h5 class="card-text">Case Overview</h5>
            <div class="progress" style="height: 30px;">
                <div class="progress-bar bg-warning progress-bar-striped progress-bar-animated" role="progressbar" style="width: 30%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                    Legal Review - 30% Complete
                </div>
            </div>
            <p class="text-muted mt-2">Your legal challenge is under review by our team. Next update expected: 2-3 business days.</p>
        </div>

        <!-- Case Actions -->
        <div class="mb-4">
            <h5 class="card-text">Available Actions</h5>
            <div class="d-flex flex-column">
                <button type="button" class="btn btn-primary mb-3">Submit Legal Documents</button>
                <button type="button" class="btn btn-outline-primary mb-3">Request Legal Consultation</button>
                <button type="button" class="btn btn-outline-danger mb-3">Escalate Challenge</button>
            </div>
        </div>

        <!-- Case History & Legal Updates -->
        <div class="mb-4">
            <h5 class="card-text">Case History</h5>
            <ul class="list-group">
                <li class="list-group-item">
                    <strong>October 15, 2024:</strong> Legal team submitted GDPR complaint on your behalf.
                </li>
                <li class="list-group-item">
                    <strong>October 13, 2024:</strong> First legal review initiated, awaiting response from Activision.
                </li>
                <li class="list-group-item">
                    <strong>October 10, 2024:</strong> Case opened for ban challenge based on GDPR and UCPD.
                </li>
            </ul>
        </div>

        <!-- Contact Legal Team -->
        <div class="d-flex justify-content-between align-items-center">
            <a href="#" class="btn btn-link">Download Legal Case File</a>
            <button type="button" class="btn btn-success">Chat with Legal Support</button>
        </div>
    </div>
</div>


        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { sessionData } from '@/session';

export default {
    data() {
        return {
            cases: null,
            error: null,
            loading: true,
            sessionData // Import session data to check if API call is needed
        };
    },
    async created() {
        const startTime = Date.now(); // Record the start time

        // Check if sessionData.hasSubmittedCase is already set to true/false
        if (this.sessionData.hasSubmittedCase !== null) {
            // If true, mock successful fetch
            if (this.sessionData.hasSubmittedCase) {
                this.cases = this.sessionData.cases;
            } else {
                this.error = 'No cases found for the logged-in user.';
            }
            this.loading = false; // Skip API call
        } else {
            // Proceed with API call if sessionData.hasSubmittedCase is not set
            try {
                const response = await axios.get('https://api.codfalseban.com/v1/account/case/details', { withCredentials: true });
                if (response.data.status === 'success') {
                    this.cases = response.data.cases;
                    this.sessionData.hasSubmittedCase = true; // Update sessionData based on the response
                    this.sessionData.cases = this.cases; // Store cases in session data
                } else {
                    this.error = response.data.message;
                    this.sessionData.hasSubmittedCase = false; // Update sessionData based on the response
                }
            } catch (err) {
                this.error = 'An error occurred while fetching case details.';
                console.error(err);
            } finally {
                const endTime = Date.now(); // Record the end time
                const elapsedTime = endTime - startTime; // Calculate the elapsed time

                // Artificial delay to ensure a minimum spinner time of 2 seconds
                const delay = Math.max(0, 2000 - elapsedTime);
                setTimeout(() => {
                    this.loading = false;
                }, delay);
            }
        }
    },
    methods: {
        formatDate(dateStr) {
            if (!dateStr) return 'N/A';
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateStr).toLocaleDateString(undefined, options);
        }
    }
};
</script>

<style scoped>
/* Styles for loading spinner and container */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    /* Adjust the height as needed */
    margin: 20px 0;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* You can add more styles or customize the alert message here */
</style>
