<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Guidelines</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Tips for navigating the complexities of account suspensions.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-8">
                        <h1 class="fw-bold">Activision’s GDPR non-compliance</h1>
                        <p class="text-muted" style="font-size: 0.9em;">
                            <i class="fas fa-calendar-alt"></i> Posted on October 13, 2024
                            <span style="margin: 0 5px;"></span>
                            <i class="fas fa-clock"></i> Read time: 6 minutes
                        </p>
                        <hr>
                        <div style="width: 100%;">
                            <img class="mt-3"
                                src="https://cdn.codfalseban.com/assets/images/activision_blizzard_reuters_1642831258846.png"
                                style="width: 100%; height: auto;">
                        </div>

                        <p class="mt-4 fw-bold">Activision Blizzard, the gaming giant behind Call of Duty,
                            seems to be playing a frustrating game with user data. Reports indicate they're
                            systematically denying GDPR (General Data Protection Regulation) requests related to
                            cheating accusations by citing Recital 63, a provision meant to protect intellectual
                            property like algorithms and technical details. However, this is a blatant misapplication of
                            the law.
                        </p>

                        <br>

                        <h2 class="fw-bold"> Understanding your rights</h2>

                        <p>Under the GDPR, individuals have specific rights regarding their personal data. One of the
                            most significant rights is the ability to access information that companies hold about you.
                            This includes the right to know what personal data is being processed, for what purposes,
                            and the legal basis for the processing. Moreover, you have the right to obtain a copy of
                            your data, as well as any relevant details concerning its processing.
                        </p>


                        <h2 class="fw-bold mt-5">Retical 63 and its implications</h2>

                        <p>Recital 63 of the GDPR is designed to protect trade secrets and sensitive technical
                            information, but it’s being misused by companies like Activision. The recital is meant to
                            cover things like algorithms and technical details—not user data such as logs, dates of
                            cheating incidents, or detected cheats. By invoking this provision, Activision is
                            sidestepping their responsibility to provide users with access to their data.
                        </p>

                        <h2 class="fw-bold mt-5">Guidelines 01/2022 on Data Subject Rights</h2>

                        <p>
                            The European Data Protection Board (EDPB) has laid out clear guidelines regarding data
                            subject rights, particularly the right of access, in their document Guidelines 01/2022. A
                            relevant example from these guidelines illustrates the responsibilities of companies when
                            handling user requests:
                        </p>
                        <p>
                            <strong>Example 37:</strong> <i>"Upon receipt of GAMER X’s request for access, PLATFORM Y
                                shall provide GAMER X with a copy of the personal data processed about them. PLATFORM Y
                                should inform GAMER X that their access to online games has been restricted due to the
                                use of gaming cheats, which violate the general terms of use. In addition to the
                                information regarding the processing for the purpose of gaming cheat detection, PLATFORM
                                Y shall grant GAMER X access to the information stored about their gaming cheats that
                                led to the restriction. PLATFORM Y should provide GAMER X with specific information that
                                contributed to the account restriction, including the log overview, the date and time of
                                cheating incidents, and the detection of third-party software. This information will
                                enable GAMER X to verify the accuracy of the data processing."</i>
                        </p>

                        <p>This example clearly differentiates between information about the user's actions (which must
                            be disclosed) and the technical workings of the anti-cheat system (which are protected).
                            Activision is conveniently ignoring this distinction.</p>

                        <p>It's important to note that the guidelines also acknowledge the need to protect legitimate
                            trade secrets. As they state:
                        </p>

                        <p>
                            <strong>Example 37:</strong> <i>"However, according to Art. 15(4) GDPR and Recital 63 GDPR,
                                PLATFORM Y is not bound to
                                reveal any part of the technical operation of the anti-cheat software even if this
                                information relates to GAMER X, as long as this is can be regarded as trade secrets. The
                                necessary balancing of interests under Art. 15(4) GDPR will have the result that the
                                trade secrets of PLATFORM Y preclude the disclosure of this personal data because
                                knowledge of the technical operation of the anti-cheat software could also allow the
                                user to circumvent future cheat or fraud detection."</i>
                        </p>

                        <p>This reinforces the idea that companies can protect the "how" of their anti-cheat systems,
                            but they cannot use this as an excuse to hide information about the "what" – the specific
                            actions that led to a user's account being restricted.

                        </p>

                        <h2 class="fw-bold mt-5">The consequences of Activision's actions</h2>
                        <p>Activision’s refusal to comply with GDPR requests and the misuse of Recital 63 could create
                            serious problems for both the company and its users. By denying access to important
                            information about account restrictions, Activision isn’t just frustrating players; it’s also
                            risking being seen as misleading under the Unfair Commercial Practices Directive (UCPD).
                        </p>

                        <p>
                            When players receive account bans or restrictions, they deserve to know the reasons behind
                            those actions. If Activision fails to provide clear explanations or simply points to vague
                            terms like "cheating," it leaves users in the dark. This lack of transparency can make
                            players feel unjustly treated, as they might not understand what specific behavior led to
                            the ban. When companies withhold this information, it can create an impression that they’re
                            trying to hide something.
                        </p>

                        <h2 class="fw-bold mt-5">What can gamers do?</h2>
                        <p>
                            If you're a gamer feeling frustrated by Activision's handling of your data and account
                            restrictions, there are steps you can take to protect your rights. First, familiarize
                            yourself with your rights under the GDPR, which include accessing your personal data and
                            understanding the reasons for any account restrictions.
                        </p>
                        <p>
                            To request your data, you can email Activision’s Data Protection Officer at
                            <a href="mailto:DPO@activision.com">DPO@activision.com</a>. In your email, clearly state
                            that you’re making a GDPR request and
                            specify what information you want. For example, you might ask for details about any data
                            they have on your account, the reasons for any restrictions, and any logs or information
                            related to cheating accusations.
                        </p>

                        <p>Under GDPR, Activision is obligated to respond to your request within 30 days. They can
                            extend this period to a
                            maximum of 2 months. In such cases, they are required to inform you that your request will
                            take longer and explain the reasons for the delay.</p>

                        <p>
                            Make sure to keep a record of your email and any responses you receive. If Activision
                            provides vague information or fails to respond within the specified time frame, you have the
                            right to escalate the issue by contacting your localData Protection Authority. They can
                            investigate the matter and help enforce your rights.
                        </p>

                        <h2 class="fw-bold mt-5">Conclusion</h2>
                        Activision Blizzard's apparent misuse of Recital 63 to deny legitimate GDPR requests is a
                        worrying trend. It not only undermines the rights of individual gamers but also erodes trust in
                        the gaming industry as a whole. By hiding behind vague accusations and refusing to provide
                        transparency, Activision risks turning away its own player base and facing legal
                        consequences, including sanctions and fines from regulators.
                    </div>

                </div>
            </div>
        </section>


    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>